import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import styled from 'styled-components'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import { createStore } from 'redux';

const Wrapper = styled.div`

  @font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('/fonts/opensans-semibold-webfont.woff2') format('woff2'),
      url('/fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('/fonts/opensans-extrabold-webfont.woff2') format('woff2'),
      url('/fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  a, p, button, div, li, ol {
    font-family: 'OpenSans-SemiBold';
    letter-spacing: -0.015em;
  }
  h1, h2, .bold {
    font-family: 'OpenSans-ExtraBold';
    font-weight: bold;
    letter-spacing: -0.015em;
  } 
  h3, h4, h5 {
    font-family: 'OpenSans-SemiBold';
    letter-spacing: -0.015em;
  }
  section {
    width: 100%;
    border-top: 1px solid #e1e1e1;
    margin: 1rem 0;
  }
`;


const store = createStore(rootReducer, {
  ui: {
    menuBarStatus: 0,
    menuBarTransition: 0
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Wrapper>
      <App />
    </Wrapper>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
