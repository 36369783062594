import styled from 'styled-components';
import { textColor } from '../../../theme';
import { containerWidths } from '../../../styles';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1100px;
    text-align: left;
    padding-top: 1.5rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    
    @media(min-width: ${containerWidths.mobile + 1}px) {
      margin-top: 0;
    }


    h2 {
      padding-bottom: 2rem;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      height: auto;
      margin: 1.5rem 0 1rem 0;

      @media(min-width: ${containerWidths.mobile + 1}px) {
        margin: 0rem 0 1rem 0;
      }

      img {
        height: 45px;
        margin: 0.5rem 1rem;

        @media(min-width: ${containerWidths.mobile + 1}px) {
          height: 50px;
          margin: 0.75rem 1.5rem;
        }
        @media(min-width: ${containerWidths.tablet + 1}px) {
          height: 65px;
          margin: 1rem 2rem;
        }

        path {
          fill: ${textColor};
        }
      }
    }
`;