import { containerPadding, containerWidths } from './styles';
import { css } from 'styled-components';

export const mediaContainer = css`
  padding: 50px ${containerPadding.mobile} 0 ${containerPadding.mobile};

  @media(min-width: ${containerWidths.mobile + 1}px) {
    padding: 50px ${containerPadding.tablet} 0 ${containerPadding.tablet};
  }

  @media(min-width: ${containerWidths.desktop}px) {
    padding: 50px ${containerPadding.desktop} 0 ${containerPadding.desktop};
  }
`;

export const mediaH1 = css`
  h1 {
    font-size: 1.7rem;
    
    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 2rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 2.45rem;
    }
  }
`;

export const mediaH2 = css`
  h2 {
    font-size: 1.3rem;
    line-height: 1.75rem;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 1.1rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 1.3rem;
      line-height: 1.95rem;
    }
  }
`;

export const mediaH3 = css`

  h3, .subtitle {
    font-family: OpenSans-SemiBold;
    font-size: 1rem;
    line-height: 1.65rem;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 1rem;
    }
  }

  h3 {
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 1rem;
    }
  }
  .subtitle {
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 1.1rem;
      line-height: 1.95rem;
    }
  }
`;