import React from 'react'
import { Tooltip } from 'react-tooltip';
import { Wrapper } from './Wrapper'

export const Partners = (props: any) => {

  return (
    <Wrapper>
      <Tooltip />
      <h2>CMPs produced for the following London Boroughs</h2>
      <div>
        <img src={require('../../../img/b18.png')} alt="Barking &amp; Dagenham" data-tip="Barking" />
        <img src={require('../../../img/b17.png')} alt="Brent" data-tip="Brent" />
        <img src={require('../../../img/b6.png')} alt="Camden" data-tip="Camden" />
        <img src={require('../../../img/b4.png')} alt="City of London" data-tip="City of London" />
        <img src={require('../../../img/b11.png')} alt="Ealing" data-tip="Ealing" />
        <img src={require('../../../img/b8.png')} alt="Enfield" data-tip="Enfield" />
        <img src={require('../../../img/b1.png')} alt="Hammersmith &amp; Fulham" data-tip="Hammersmith &amp; Fulham" />
        <img src={require('../../../img/b13.png')} alt="Haringey" data-tip="Haringey" />
        <img src={require('../../../img/b12.png')} alt="Harrow" data-tip="Harrow" />
        <img src={require('../../../img/b9.png')} alt="Havering" data-tip="Havering" />
        <img src={require('../../../img/b19.png')} alt="Islington" data-tip="Islington" />
        <img src={require('../../../img/b3.png')} alt="Lambeth" data-tip="Lambeth" />
        <img src={require('../../../img/b16.png')} alt="Merton" data-tip="Merton" />
        <img src={require('../../../img/b20.png')} alt="Richmond-upon-Thames" data-tip="Richmond-upon-Thames" />
        <img src={require('../../../img/b5.png')} alt="Royal Borough of Kensington and Chelsea" data-tip="Royal Borough of Kensington and Chelsea" />
        <img src={require('../../../img/b2.png')} alt="Southwark" data-tip="Southwark" />
        <img src={require('../../../img/b15.png')} alt="Tower Hamlets" data-tip="Tower Hamlets" />
        <img src={require('../../../img/b10.png')} alt="Waltham Forest" data-tip="Waltham Forest" />
        <img src={require('../../../img/b14.png')} alt="Wandsworth" data-tip="Wandsworth" />
        <img src={require('../../../img/b7.png')} alt="Westminster" data-tip="Westminster" />
      </div>
    </Wrapper>
  )
}

export default Partners;