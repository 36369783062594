import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  position: fixed;
  top: 0px;
  right: ${props => props.menuOpen === 1 ? `0px` : `-300px`};
  width: 300px;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  border-left: 1px solid #ccc;
  z-index: 100;
  background: #fff;
  transition: all 0.2s;
  animation: ${props => props.transitionMenu === 1 ? props => props.menuOpen === 1 ? `show_menu` : `hide_menu` : ``} 0.2s;
  animation-fill-mode: forwards;

  
  .logo {
    width: 100%;

    svg {
      max-width: 200px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: 5rem;

    > div {
      padding: 1rem 0.9rem;
      width: 100%;
      font-size: 1rem;
      line-height: 1.6rem;

      svg {
        margin-left: 0.25rem;
      }

      button {
        background: none;
        border: none;
        color: #333;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0;
        cursor: pointer;
      }

      &.subheading {
        font-size: 0.8rem;
        padding-top: 0rem;
        padding-bottom: 0.4rem;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        margin-top: 1rem;
      }

      a {
        color: #70af1a;
        font-weight: 600;

        &:hover {
          color: #559004;
        }
      }

      .close {
        border: none;
        padding: 0.6rem;
      }

      &:first-child {
        display: flex;
        flex-flow: row wrap;

        svg {
          max-width: 120px;
          margin: 0;
        }
        > div {
          flex-grow: 1;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
        }
      }
    }
  }
`;

export default Wrapper;