import React from 'react';
import styled from 'styled-components';
import { buttonHoverColor } from '../theme';
import { containerWidths } from '../styles';

export const Footer = () => {

  const Wrapper = styled.div`
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 100%;
    text-align: center;
    flex: 0;
    width: 100%;
    padding: 1.5rem;
    font-weight: bold;
    font-size: 0.9em;
    color: #fff;
    color: #07486e;
    border-top: 1px solid #f2f2f2;
    background: #fcfcfc;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;

      @media(max-width: ${containerWidths.mobile}px) {
        flex-basis: 100%;
        justify-content: center;
        padding: 1rem;
      }

      a {
        color: #07486e;
        margin-left: 2px;
      }
    }

    > label {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-self: flex-end;
      height: inherit;
      align-items: center;
      justify-content: flex-end;
      min-width: 200px;

      @media(hover: hover) and (pointer: fine) {
        transition: color 0.15s;
        &:hover {
          color: ${buttonHoverColor};
        }
      }

      @media(max-width: ${containerWidths.mobile}px) {
        flex-basis: 100%;
        justify-content: center;
      }
    }
  `;

  const year = (new Date()).getFullYear();

  return (
    <Wrapper>
      <div>
        &copy; CMP Construct Ltd {year}. &nbsp; Built by <a href="https://jkrbinvestments.com" target="_blank" rel="noopener noreferrer">JKRB</a>
      </div>
    </Wrapper>
  );
}