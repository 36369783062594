import styled from 'styled-components';
import { containerWidth, containerWidths } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  width: 100%;
  position: relative;
  padding-top: 2.5rem;
  margin-bottom: 5rem;

  .anc {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  > .contact {
    margin-top: 45px;
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    max-width: 1100px;
    position: relative;
    padding: 0 1.75rem;

    h1 {
      margin: 0 1rem 0rem 1rem;
      word-wrap: break-word;
      word-break: break-all;
    }

    h2 {
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
    }

    h3 {
      &.email {
        margin-top: 0;
      }
    }

    .email {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-flow: row wrap;

      > div {
        width: 100%;
        margin: 0.5rem 1.25rem;
        font-size: 1rem;
        padding-top: 0.5rem;
        span {
          margin-right: 1rem;
        }
        a {
          color: #70af1a;

          &:hover {
            color: #559004;
          }
        }
      }
      }
    }
    .btn {
      background: none;
      font-size: 1.2rem;
      width: 2.8rem;
      height: 2.8rem;
      margin: 0;
      border: none;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      margin: 0.5rem 0.5rem 0rem 0.5rem;
      color: #70af1a;
      display: flex;
      align-items: center;
      justify-content: center;
      
      @media(max-width: ${containerWidth}) {
        font-size: 0.9rem;
        width: 2.2rem;
        height: 2.2rem;
      }

      @media(hover: hover) and (pointer: fine) {
        transition: all 0.15s;
        &:hover {
          background: #f2f2f2;
        }
      }
    }
    > div {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      align-content: center;

      h2, h4 {
        flex: 1;
        flex-basis: 100%;
    }
  }

  .form {
    width: 100%;
    padding: 0 0.5rem;
    margin-bottom: 1.5rem;
    .cont {
      width: 100%;
      @media(min-width: ${containerWidths.mobile + 1}px) {
        width: 65%;
      }
      .field {
        padding: 0.25rem;
        input {
          width: 100%;
          font-size: 0.95rem;
        }
      }
      .textarea {
        font-family: sans-serif;
        width: 100%;
        min-height: 100px;
        font-size: 0.95rem;
      }
      .error {
        margin: 0;
        padding: 0 0.1rem;
        color: #510000;
      }

      input, .textarea {
        padding: 0.6rem 0.75rem;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-weight: 500;
        color: #07486e;
        margin: 0.25rem 0;
      }
      .submit {
        font-size: 1.1rem;
        color: #70af1a;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
`;