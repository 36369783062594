import styled from 'styled-components';
import { textColor } from '../theme';
import { containerWidths } from '../styles';

export const Logo = styled.div`
  svg {
    height: 42px;
    margin-left: 0.25rem;
    fill: ${textColor};

    @media(max-width: ${containerWidths.mobile}px) {
      max-height: 32px;
    }
  }
`;

export default Logo;