import React from 'react';
import styled from 'styled-components';
import { NotifierContextState } from '../types';
import { notifierBackgroundColor } from '../theme';

const defaultStatus = 'closed';
const defaultMessage = 'default';

export const NotifierContext: React.Context<NotifierContextState> = React.createContext({
  notifier: defaultMessage,
  status: defaultStatus,
  call: () => { }
});

export const useNotifier = () => React.useContext(NotifierContext);

export class NotifierManager extends React.Component<any, any> {
  state = {
    status: defaultStatus,
    notifier: defaultMessage
  };

  render () {

    const call = (status: string, notifier: string): void => {
      this.setState({
        status: status,
        notifier: notifier
      });
    }

    return (
      <NotifierContext.Provider value={{
        status: this.state.status,
        notifier: this.state.notifier,
        call: call
      }}>
        {this.props.children}
      </NotifierContext.Provider >
    );
  }
}

export const Notifier: React.FC = () => {

  const notifier = useNotifier();

  const Wrapper = styled.div`
    width: 100%;
    z-index: 3;
    position: fixed;
    bottom: -100%;
    left: 0px;

    @keyframes notifier-open {
      from { bottom: -100px }
      to { bottom: 0px }
    }

    @keyframes notifier-close {
      from { bottom: 0px }
      to { bottom: -100px }
    }

    &.notifier-open {
      animation: notifier-open cubic-bezier(0.23, 1, 0.32, 1) 1;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-delay: 0s;
    }

    &.notifier-close {
      animation: notifier-close cubic-bezier(0.23, 1, 0.32, 1) 1;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-delay: 0s;
    }

    > div {
      padding: 10px;
      background-color: ${notifierBackgroundColor};
      text-align: center;
      margin: 5px 10px;
      border-radius: 10px;
    }
  `;

  let statusClass;

  switch (notifier.status) {

    case 'open':
      statusClass = 'notifier-open';

      setTimeout(() => {
        notifier.call('closing', notifier.notifier);
      }, 1250);
      break;

    case 'closing':
      statusClass = 'notifier-close';
      setTimeout(() => {
        notifier.call('closed', '');
      }, 500);
      break;

    default:
      statusClass = '';
  }

  return (
    <Wrapper className={statusClass}>
      <div>
        <h2>{notifier.notifier}</h2>
      </div>
    </Wrapper>

  );
}

export default Notifier;
