import theme from 'styled-theming';
import { textColors, buttonColors, backgroundSecondaryColor, headerBackgroundColors } from './styles';

export const backgroundColor: theme.ThemeSet = theme('mode', {
  light: textColors.light,
  dark: textColors.dark
});

export const headerBackgroundColor: theme.ThemeSet = theme('mode', {
  light: headerBackgroundColors.light,
  dark: headerBackgroundColors.dark
});

export const textColor: theme.ThemeSet = theme('mode', {
  light: '#191919',
  dark: '#fafafa'
});

export const labelColor: theme.ThemeSet = theme('mode', {
  light: '#333',
  dark: '#ddd'
});

export const tagColor: theme.ThemeSet = theme('mode', {
  light: '#999',
  dark: '#999'
});

export const formBackgroundColor: theme.ThemeSet = theme('mode', {
  light: backgroundSecondaryColor.light,
  dark: backgroundSecondaryColor.dark
});

export const formBackgroundHover: theme.ThemeSet = theme('mode', {
  light: '#e6e6e6',
  dark: '#3c3c3c'
});

export const buttonColor: theme.ThemeSet = theme('mode', {
  light: buttonColors.light,
  dark: buttonColors.dark
});

export const buttonHoverColor: theme.ThemeSet = theme('mode', {
  light: '#000',
  dark: '#fff'
});

export const buttonActiveColor: theme.ThemeSet = theme('mode', {
  light: '#fff',
  dark: '#1a1a1a'
});

export const notifierBackgroundColor: theme.ThemeSet = theme('mode', {
  light: '#fff',
  dark: '#030303'
});
