import styled from 'styled-components';
import { headerBackgroundColors, containerWidths } from '../styles';
import { HeaderWrapper } from '../types';

export const Wrapper = styled.div<HeaderWrapper>`

  @keyframes switch {
    from {
      background-color: ${props => props.theme === 'light'
    ? headerBackgroundColors.dark
    : headerBackgroundColors.light};
    }
    to {
      background-color: ${props => props.newColor}
    }
  }

  @keyframes show_header {
    from { top: -75px; }
    to { top: 0px; }
  }

  @keyframes hide_header {
    from { top: 0px; }
    to { top: -75px; }
  }

  ${props => props.playTransition
    ? ``
    : `background-color: ${props.newColor}; `
  }
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  height: 68px;
  justify-content: center;
  padding: 0 0.75rem;
  position: fixed;
  left: 0px;
  width: 100%;
  transition: all 0.2s;
  z-index: 10;
  box-shadow: 0px 1px 2px #f2f2f2;

  svg {
    max-height: 40px;
    @media(max-width: ${containerWidths.mobile}px) {
      max-height: 36px;
    }
  }

  
  @media(min-width: ${containerWidths.mobile + 1}px) {
    padding: 0rem 1.5rem 0 1.5rem;
  }

  .tab-only {
    @media(min-width: 750px) {
      display: none;
    }
  }
  
  .tab-hidden {
    @media(max-width: 751px) {
      display: none;
    }
  }

  div {
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: 100%;

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      margin-left: 1rem;
      font-size: 1rem;
    }
  }

  /* smaller header on mobile */
  @media(max-width: ${containerWidths.mobile}px) {
    height: 64px;
    > div:first-child {
      svg {
        height: 40px;
      }
    }
  }
`;

export default Wrapper;