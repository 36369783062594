export const ui = (state = {}, action: any) => {

  switch (action.type) {

    case 'TOGGLE_MENU_BAR':
      return Object.assign({}, state, {
        menuBarStatus: action.status,
        menuBarTransition: 1
      });

    default:
      return state;
  }
}

export default ui;