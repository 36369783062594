import React from 'react';
import styled from 'styled-components';
import { mediaContainer } from '../../media';
import { containerWidths } from '../../styles';
import { formBackgroundHover } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import { Button } from '../../Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const About = () => {

  const Wrapper = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    align-content: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    ${mediaContainer};
    max-width: 1100px;
    margin: 0 auto;

    h1 {
      margin-top: 0 !important;
    }

    button {
      margin: 0em 0.6em 0.75rem 0;
      font-size: 0.9rem;

      &:first-child {
        margin-left: 0;
      }

      @media(min-width: ${containerWidths.mobile + 1}px) {
        margin: 0em 0.5em 1em 0.4em;
        font-size: 1rem;
      }
      @media(min-width: ${containerWidths.tablet + 1}px) {
        margin: 0em 0.75em 1em 0.5em;
      }
    }

    .back {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .anc {
      position: absolute;
      top: 0px;
      left: 0px;
    }
   
    > div {
      display: flex;
      flex: 1;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;
      width: 100%;
      word-wrap: break-word;
      position: relative;

      @media(min-width: ${containerWidths.mobile}px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }

      @media(min-width: ${containerWidths.tablet}px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      
      > .specialisation {
        margin: 1.5rem 1rem;

        h1, h2, h3, h4, p {
          margin-left: 0;
          margin-right: 0;
        }

        h3 svg {
          margin-right: 0.75rem;
        }

        h3 a {
          color: #70af1a;

          &:hover {
            color: #559004;
          }
        }

        .img {
          width: 100%;
          text-align: center;
          margin: 2rem 0;
          
          img {
            width: 100%;
            max-width: 900px;
            border-radius: 4px;
          }
        }
        

        p, li {
          font-size: 1rem;
          line-height: 1.6rem;
        }
        ul {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    section {
      width: 100%;
      border-top: 1px solid ${formBackgroundHover};
      margin: 1.5rem 0 1rem 0;
    }
  `;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://cmpconstruct.com/about" />
        <meta name="title" content="About CMP Construct"></meta>
        <meta name="description"
          content="If you are looking for an experienced, knowledgeable and flexible team for developing your Construction Management Plan, you are at the right place. At CMPConstruct we value results and effectiveness. Therefore, if you are opting for a CMP, your Plan will be in the right hands." />
        <title>About | CMP Construct</title>
      </Helmet>
      <Wrapper>
        <div>
          <p>
            <a href="/">
              <Button label="< Back Home" />
            </a>
          </p>
          <h1 className='title'>About CMPConstruct</h1>
          <h2 className='subtitle'>If you are looking for an experienced, knowledgeable and flexible team for developing your Construction Management Plan, you are at the right place. At CMPConstruct we value results and effectiveness. Therefore, if you are opting for a CMP, your Plan will be in the right hands.</h2>
          <section></section>
          <div className='specialisation'>

            <h3>Our extensive knowledge allows us to cater for many different types of plans. From small projects such as basement extensions for single dwellings to bigger projects such as large-scale multi-million-pound developments.</h3>

            <h3>We are specialised in in-house development, where we thoroughly analyse every individual project we have and create a bespoke CMP solution for your needs. In addition to our construction management plans, we can organise a lot of supporting activities as well such as noise survey or site logistics if required.</h3>

            <h3>Construction Management Plans have become mandatory if you plan to build anything in the area of London and in many other UK cities. As such a CMP is a required document by councils for most developments and it plays a crucial part in the Planning Approval process which you need to have if you wish to begin construction.</h3>

            <h3>We create CMPs in line with all the guidelines issued by the relevant borough council and to ensure there are no complications when it comes to pre-construction documentation. Usually, we need between 7-10 working days for a simple CMP which is sufficient for most projects. For more complex solutions, we would need to see the whole project and propose to you the solution as well as the timeframe of the finished product. We will strive to provide the perfect solution to ensure your project success.</h3>

            <h3>So, if you're looking for a proven and professional partner for all your CMP related issues, don't hesitate to contact us.</h3>

            <section></section>
            <h3>
              <a
                href="/contact"
              >
                <FontAwesomeIcon icon={faEnvelope as IconProp} />
                Get in touch with us today
              </a>
            </h3>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default About;