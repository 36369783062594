import React from 'react';
import { Landing } from './Landing';
import { About } from './About';
import { NotifierManager, Notifier } from '../../Notifier';
import Partners from './Partners'

const Home: React.FC = () => {

  return (
    <>
      <Landing />
      <About />
      <Partners />
      <NotifierManager>
        <Notifier />
      </NotifierManager>
    </>
  );
}

export default Home;