export const textColors: any = {
  light: '#fafafa',
  dark: '#0e0f11'
}

export const headerBackgroundColors: any = {
  light: 'rgba(255,255,255,0.97)',
  dark: 'rgba(16,16,16,0.97)'
}

export const backgroundSecondaryColor: any = {
  light: '#eee',
  dark: '#333'
}

export const buttonColors: any = {
  light: '#435264',
  dark: '#dde2e7'
}

export const containerWidths = {
  mobile: 600,
  tablet: 850,
  desktop: 1100
}

export const containerWidth = {
  mobile: containerWidths.mobile + 'px',
  tablet: containerWidths.tablet + '0px',
  desktop: containerWidths.desktop + 'px'
}

export const containerPadding = {
  mobile: '0.75rem',
  tablet: '2rem',
  desktop: '2rem'
}
