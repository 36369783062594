import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from './ThemeManager';
import { mediaH1, mediaH2, mediaH3 } from './media';
import { textColor, buttonHoverColor } from './theme';
import { Header } from './Header';
import { Footer } from './Footer';
import Home from './pages/Home';
import { About } from './pages/About';
import { WhatIs } from './pages/WhatIs';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Menu } from './Menu';
import { Contact } from './pages/Contact/';

const Wrapper = styled.div<any>`
  background-color: #fcfcfc;
  color: #07486e;
  min-height: 100vh;
  text-align: left;
  transition: background-color 0.35s;
  h4 {
    font-size: 0.95rem;
    line-height: 1.4em;
  }
  p {
    font-size: 0.95rem;
  }
  h1, h2, h3, h4, p {
    margin: 1rem;
    width: 100%;
  }
  a {
    color: ${textColor};
    text-decoration: none;
    @media(hover: hover) and (pointer: fine) {
      transition: all 0.15s;
      &:hover {
        color: ${buttonHoverColor};
      }
    }
  }
  ${mediaH1}
  ${mediaH2}
  ${mediaH3}
`;

const App = () => {

  const theme = useTheme();

  return (
    <ThemeProvider theme={{ mode: theme.mode }}>
        <BrowserRouter>
          <Wrapper>
            <Header />
            <Menu />
            <Routes>
              <Route path="/what-is-a-construction-management-plan" element={<WhatIs />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
            </Routes>
            <Footer />
        </Wrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;