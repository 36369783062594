import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from '../types';

export const Button: React.FC<ButtonProps> = (props) => {

  let haveBorder = true;
  if (props.border === false) {
    haveBorder = false;
  }

  const StyledButton = styled.button<any>`
    border: none;
    padding: ${haveBorder === false ? `0` : `0.45em 1.2em`};
    border-radius: 50px;
    font-size: 1rem;
    background: none;
    border-color: #70af1a;
    border: ${haveBorder === false ? `none !important` : `1px solid`};
    cursor: pointer;
    color: #70af1a;
    font-weight: 550;
    outline: none;
    transition: all 0.15s;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: #559004;
        border-color: #559004;
      }
    }

    &:active {
      background: none;
    }
  `;

  return (
    <StyledButton
      border={haveBorder}
    >
      {props.label}
    </StyledButton>
  )
}