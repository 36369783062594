import React from 'react';
import styled from 'styled-components';
import { containerWidths } from '../styles';
import { ButtonProps } from '../types';

export const ButtonPrimary: React.FC<ButtonProps> = (props) => {

  const StyledButton = styled.button`
    border: none;
    padding: 0.45em 1.20em;
    border-radius: 50px;
    font-size: 1.05rem;
    background: none;
    border: 1px solid #70af1a;
    cursor: pointer;
    color: #70af1a;
    font-weight: 600;
    outline: none;
    transition: all 0.15s;

    @media(max-width: ${containerWidths.mobile}px) {
      font-size: 0.9rem;
      padding: 0.45em 1.20em;
    }

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: #559004;
        border-color: #559004;
      }
    }

    &:active {
      background: none;
    }
  `;

  return (
    <StyledButton>
      {props.label}
    </StyledButton>
  )
}